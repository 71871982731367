/* after sm (tablet) */
@screen sm {
}

/* after lg (Desktop) */
@screen lg {
    h1 {
        @apply !text-5xl;
    }
    h2 {
        @apply !text-[2.5rem] !leading-10;
    }
    h3 {
        @apply !text-3xl font-tienne;
    }
    h4 {
        @apply !text-2xl font-tienne;
    }

    .rounded-tr-bl > * {
        &:nth-child(3) {
            border-top-right-radius: 100px;
        }
        &:nth-child(7) {
            border-bottom-left-radius: 100px;
        }
    }

    .stretch-to-right,
    .stretch-to-left {
        width: calc(1360px + ((100vw - 1360px) / 2));
    }

    .before-stretch {
      &::before {
        width: calc(1346px + ((100vw - 1380px) / 2));
      }
    }

    .stretch-to-left {
        margin-left: calc(((100vw - 1360px) / 2) * -1);
    }

    .before-stretch-to-left-cols-2 {
      &::before {
        width: calc(500px + ((100vw - 500px) / 2));
        margin-left: calc(((100vw - 500px) / 2) * -1);
      }
    }
}
