/* all screen size (default) */
body {
  @apply overflow-x-hidden
}

h1,
h2 {
    @apply font-tienne;
}

h1 {
    @apply !text-4xl;
}
h2 {
    @apply !text-3xl;
}
h3 {
    @apply !text-2xl font-tienne;
}
h4 {
    @apply !text-xl font-tienne;
}

p {
    @apply font-poppins;
}

.button {
      @apply !uppercase inline-flex items-center justify-center !px-6 !py-[.85rem] min-w-[100px] rounded-[5px] !font-poppins !font-semibold transition-all duration-150 ease-in-out;

    &.primary {
        @apply bg-blue-dark border border-transparent text-white hover:bg-blue-darkest disabled:bg-transparent disabled:text-gray-dark disabled:cursor-not-allowed disabled:border-gray-dark/50 disabled:border-2;
    }

    &.secondary {
        @apply text-blue-dark border border-gray-dark hover:text-blue hover:border-blue disabled:text-gray-dark disabled:cursor-not-allowed disabled:border-gray-dark/50;
    }

    &.tertiary {
        @apply bg-white text-blue-dark hover:text-blue  disabled:text-gray-dark disabled:cursor-not-allowed;
    }
}

.hero {
    background: linear-gradient(
            0deg,
            rgba(18, 37, 83, 0.5),
            rgba(18, 37, 83, 0.5)
        ),
        linear-gradient(0deg, rgba(18, 37, 83, 0.5), rgba(18, 37, 83, 0.5));
    background-blend-mode: multiply, color, normal;
    .hero__overlay {
        background: rgba(18, 37, 83, 0.3);
        backdrop-filter: blur(15px);
        width: calc(85rem + 15%);
    }
}

.underlined {
    @apply relative;
    &:after {
        @apply content-[''] absolute inset-x-0 m-auto bg-blue -bottom-3 h-[3px] block max-w-[100px];
    }
    &-start {
        &:after {
            @apply mr-auto ml-0;
        }
    }
}

.rounded-tr-bl > * {
    &:nth-child(3) {
        border-top-right-radius: 4px;
    }
    &:nth-child(7) {
        border-bottom-left-radius: 4px;
    }
}

.floating {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    outline: none;
    width: 100%;
    position: relative;

    &:hover {
        .floating-inner {
            background-color: transparent;
            cursor: pointer;
        }
    }

    .floating-inner {
        display: flex;
        box-align: center;
        align-items: center;
        position: relative;
        background-color: transparent;

        &:has(.floating-input:not([disabled]):focus) {
            border-color: blue !important;
        }
    }

    .floating-control {
        display: flex;
        box-flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        cursor: text;
        min-width: 0%;
        position: relative;
        border-radius: 8px;
        background-color: transparent;

        &--has-icon {
            i,
            svg {
                @apply absolute right-3 top-4 text-lg;
            }
            .floating-input {
                padding-right: 40px;
            }
        }
        &--has-button {
          button {
            @apply absolute right-0 inset-y-0 flex items-center justify-center min-w-0 !pr-3 rounded text-white;
              svg,
              path {
                @apply text-gray-dark !stroke-gray-dark
              }
              &:hover {
               svg,
               path {
                @apply !stroke-blue-dark
               }
              }
          }
          .floating-input {
            padding-right: 40px;
          }
        }
    }

    .floating-input {
        box-flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 24px;
        background-color: transparent;
        /* color: theme('colors.blue'); */
        border: none;
        box-sizing: content-box;
        caret-color: theme('colors.blue-dark');
        display: block;
        margin: 0;
        padding: 14.5px 16px;
        min-width: 0%;
        outline: none;
        z-index: 0;
        border: 1px solid theme('colors.gray-dark');
        border-radius: 5px;
        box-shadow: none !important;
        background: content-box;

        &:not([disabled]):focus ~ .floating-text,
        &:not(:placeholder-shown) ~ .floating-text,
        &:-webkit-autofill ~ .floating-text {
            transform: scale(0.85) translateY(-32px);
            background-color: theme('colors.white');
            left: 12px;
        }

        &:not([disabled]):hover {
            border: 1px solid theme('colors.blue');
        }

        &:not([disabled]):focus {
            border: 1px solid theme('colors.blue');
        }

        &::placeholder {
            color: transparent;
        }
    }

    .floating-text {
        transform-origin: bottom left;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: color, transform;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 24px;
        color: theme('colors.blue-dark');
        left: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
        position: absolute;
        top: 16px;
        padding: 0 6px;
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            background-color: inherit;
            left: 0;
            right: 0;
        }
    }

    &.invalid {
        .floating-text {
            color: theme('colors.red') !important;
        }
        .floating-input {
            border: 1px solid theme('colors.red') !important;
        }
    }

    &.valid {
        .floating-text {
            color: theme('colors.green') !important;
        }
        .floating-input {
            border: 1px solid theme('colors.green') !important;
        }
    }

    .invalid--feedback {
        color: theme('colors.red');
    }

    .valid--feedback {
        color: theme('colors.green');
    }

    .visible {
      .fa-eye {
        display: none !important;
      }
      
      .fa-eye-slash {
        display: block !important;
      }
    }
}

.container {
    @apply max-w-8xl mx-auto px-4;
}

.embeded {
    @apply relative h-0 overflow-hidden max-h-full my-4;
    padding-bottom: 56.25%;
    & iframe,
    & object,
    & embed {
      @apply absolute top-0 left-0 h-full w-full;
    }
}

.vc-weeks > {
  div:nth-of-type(11),
  div:nth-of-type(12),
  div:nth-of-type(13),
  div:nth-of-type(14),
  div:nth-of-type(18),
  div:nth-of-type(19),
  div:nth-of-type(20),
  div:nth-of-type(21),
  div:nth-of-type(25),
  div:nth-of-type(26),
  div:nth-of-type(27),
  div:nth-of-type(28),
  div:nth-of-type(32),
  div:nth-of-type(33),
  div:nth-of-type(34),
  div:nth-of-type(35),
  div:nth-of-type(39),
  div:nth-of-type(40),
  div:nth-of-type(41),
  div:nth-of-type(42) {
    .event-card {
      left: unset !important;
      right: 127% !important;
      &.expanded {
        right: 122% !important;
      }
    }
    .event-card-triangle {
      left: unset !important;
      right: 100% !important;
      transform: scale(-1);
    }
  }
}

.dynamic-page-content {
    h1 {
        @apply !text-3xl;
    }

    h2 {
        @apply !text-3xl;
    }

    h3 {
        @apply !text-xl;
    }
}

[x-cloak] {
  display: none !important;
}

/* Third Party Packages Start */
.multiselect {
    --tw-border-opacity: 1;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin: inherit !important;
    background-color: white !important;
    border-width: 1px !important;
    border-radius: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    margin-top: 0 !important;
    border-color: theme('colors.gray-dark') !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    min-height: 54px !important;

    &:hover {
      border-color: theme('colors.blue') !important;
    }

    .multiselect-input {
        border: none !important;
        background: transparent !important;
    }

    .multiselect-search {
        border-radius: 0.5rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }

    .multiselect-dropdown {
        border-radius: 0.5rem !important;
    }

    .multiselect-clear {
        right: 2px !important;
    }
    .multiselect-tag {
        --tw-bg-opacity: 1;
        --tw-text-opacity: 1;
        background-color: rgba(207, 250, 254, var(--tw-bg-opacity)) !important;
        color: rgba(22, 78, 99, var(--tw-text-opacity)) !important;
    }
    .multiselect-tag i:before {
        --tw-text-opacity: 1;
        color: rgba(22, 78, 99, var(--tw-text-opacity)) !important;
    }
}

.ql-container .ql-editor{
	min-height: 30vh !important;
    max-height: 70vh !important;
	overflow: auto !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @apply !border-gray-dark;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @apply !border-gray-dark;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    padding: 3px 5px !important;
    min-width: auto;
}

.dp__input {
  @apply !border-gray-dark;
}

/* calendar  */

.custom-calendar .vc-day {
    @apply h-48 border border-gray min-w-[185px];
    z-index: unset !important;
}

.vc-pane-container.in-transition {
    overflow: visible !important;
}

/* Third Party Packages End */

span#note-error{
    color: rgb(239 68 68);
}

.prose-default {
  /* Default  */
  @apply prose text-base lg:text-normal prose-gray max-w-none; 
  /* Heading 1 */
  @apply prose-h1:!text-3xl prose-h1:lg:!text-4xl prose-h2:!leading-[48px] prose-h1:lg:!leading-[57.6px] prose-h1:font-poppins prose-h1:font-medium;
  /* Heading 2 */
  @apply prose-h2:!text-2xl prose-h2:lg:!text-3xl prose-h3:!leading-[38.4px] prose-h2:lg:!leading-[48px] prose-h2:font-poppins prose-h2:font-medium;
  /* Heading 3 */
  @apply prose-h3:!text-xl prose-h3:lg:!text-2xl prose-h3:leading-[32px] prose-h3:lg:!leading-[38.4px] prose-h3:font-poppins prose-h3:font-medium;
  /* Link */
  @apply prose-a:!text-blue-dark prose-a:hover:!text-blue-darkest;
  /* Ol */
  @apply prose-ol:list-decimal prose-ol:pl-5 prose-ol:mb-3 prose-ol:lg:mb-4;
  /* Ul */
  @apply prose-ul:list-disc prose-ul:pl-5 prose-ul:mb-3 prose-ul:lg:mb-4;

  .ql-size-small {
    @apply text-sm;
  }

  .ql-size-large {
    @apply text-xl lg:text-2xl;
  }

  .ql-size-huge {
    @apply text-3xl lg:text-4xl;
  }

  .ql-align-right {
    @apply text-right;
  }

  .ql-align-center {
    @apply text-center;
  }

  .ql-align-justify {
    @apply text-justify;
  }

  p {
    br:first-child  {
      display: none !important;
    }
  }
}

.embed-container {
  @apply relative pb-[56.25%];
  iframe {
    @apply absolute inset-x-0 w-full h-full;
  }
}

/* skeleton styles */

.skeleton {
	@apply bg-gray-dark/30 bg-no-repeat rounded;
	background-image:	linear-gradient(
    90deg, 
  rgba(255, 255, 255, 0), 
  rgba(255, 255, 255, 0.5),
  rgba(255, 255, 255, 0)
  );
	background-size: 40px 100%;
	background-position: left -40px top 0;
	animation: shine 1s ease infinite;
}

@keyframes shine {
	to {
		background-position: right -40px top 0;
	}
}

.grid-logos {
    gap: 4px;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
}