/* Page Content Styles --Start */
.page-content-container {	
	h1,
	h2,
	h3,
	strong {
		@apply font-tienne mt-6 mb-4;
	}
	
	h1 {
		@apply text-2xl sm:text-3xl;
	}
	h2 {
		@apply text-xl sm:text-2xl;
	}
	h3 {
		@apply text-base sm:text-2xl;
	}
	
	p {
		@apply font-poppins mb-2;
	}
	
	img {
		@apply w-full;
	}
	
	.ql-editor.ql-blank:before {
		left: 40px;
	}
	
	.ql-align-center {
		@apply text-center;
	}
	.ql-align-right {
		@apply text-right;
	}
	.ql-align-justify {
		@apply text-justify;
	}
	.ql-indent-1,
	.ql-indent-1:before {
		padding-left: 3em;
	}
	.ql-indent-2,
	.ql-indent-2:before {
		padding-left: 6em;
	}
	.ql-indent-3,
	.ql-indent-3:before {
		padding-left: 9em;
	}
	.ql-indent-4,
	.ql-indent-4:before {
		padding-left: 12em;
	}
	.ql-indent-5,
	.ql-indent-5:before {
		padding-left: 15em;
	}
	ul {
		@apply list-disc;
	}
	ol {
		@apply list-decimal;
	}
}
/* Page Template Styles --End */